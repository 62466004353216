<template>
  <SatuanKpiForm mode="Ubah" module="Satuan KPI"> </SatuanKpiForm>
</template>

<script>
import SatuanKpiForm from './form';

const SatuanKpiUpdate = {
  name: 'SatuanKpiUpdate',
  components: { SatuanKpiForm },
};

export default SatuanKpiUpdate;
</script>
